import React from 'react';
import { graphql, Link } from 'gatsby';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Layout, Container, Content } from 'layouts';
import { TagsBlock, Header, SEO } from 'components';
import '../styles/prism';
import theme from '../../config/theme';

const SuggestionBar = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  background: ${props => props.theme.colors.white.light};
  box-shadow: ${props => props.theme.shadow.suggestion};
`;
const PostSuggestion = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem 3rem 0 3rem;
`;

const Post = ({ data, pageContext }) => {
  const { next, prev } = pageContext;
  const { html, frontmatter, excerpt } = data.markdownRemark
  const { date, title, tags, path, description } = frontmatter
  const image = frontmatter.cover.childImageSharp.fluid;

  return (
    <Layout>
      <SEO
        title={title}
        description={description || excerpt || ' '}
        banner={image}
        pathname={path}
        article
      />
      <Header title={title} date={date} cover={image} />
      <Container>
        {frontmatter.attachment &&
          <div style={{ "textAlign": "center", "display": "block" }}>
          <a href={frontmatter.attachment.publicURL}><Button>Download article</Button></a>
          </div>
        }
        <Content input={html} />
        <TagsBlock list={tags || []} />
      </Container>
    <SuggestionBar>
      <PostSuggestion>
        {prev && (
          <Link to={prev.frontmatter.path}>
            Previous
            <h3>{prev.frontmatter.title}</h3>
          </Link>
        )}
      </PostSuggestion>
      <PostSuggestion>
        {next && (
          <Link to={next.frontmatter.path}>
            Next
            <h3>{next.frontmatter.title}</h3>
          </Link>
        )}
      </PostSuggestion>
    </SuggestionBar>
    </Layout >
  );
};

export default Post;

Post.propTypes = {
  pageContext: PropTypes.shape({
    prev: PropTypes.object,
    next: PropTypes.object,
  }).isRequired,
  data: PropTypes.object.isRequired,
};

const Button = styled.button`
  border: 2px solid ${theme.colors.primary.base};
  border-radius:5px;
  color: ${theme.colors.primary.base};
  background-color: rgba(255, 255, 128, 0.0);
  padding: 14px 28px;
  font-size: 16px;
  cursor: pointer;
  font-weight: bold;
  transition: ${theme.transitions.default.transition};
  &:hover {
    box-shadow: ${theme.shadow.feature.small.hover};
    transform: scale(1.04);
  };
  align: center;
  float: center;
`

export const query = graphql`
  query($pathSlug: String!) {
    markdownRemark(frontmatter: { path: { eq: $pathSlug } }) {
      html
      frontmatter {
        attachment {
          publicURL
          name
          ext
        }
        date
        title
        tags
        cover {
          childImageSharp {
            fluid(
              maxWidth: 1920
              quality: 90
              duotone: { highlight: "#386eee", shadow: "#2323be", opacity: 60 }
            ) {
              ...GatsbyImageSharpFluid_withWebp
            }
            resize(width: 1200, quality: 90) {
              src
            }
          }
        }
      }
    }
  }
`;
